import React from "react"
import {
  useMediaQuery,
  useTheme,
  Box,
  Container,
  Grid,
  Typography,
} from "@material-ui/core"
import { Link } from "gatsby"
import Logo from "../../Logo"
import { useStyles } from "./style"

const Footer = ({ isLight }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isOverMobile = useMediaQuery(theme.breakpoints.up("sm"))

  return (
    <Box padding="5rem 0 5rem 0" className={classes.root}>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item md={8} sm={12} xs={12}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems={isOverMobile ? "flex-start" : "center"}
              height="100%"
              paddingBottom="2rem"
            >
              <Box width="12.5rem">
                <Link to="/">
                  <Logo logoDark={isLight ? false : true} />
                </Link>
              </Box>
              <Typography variant="caption">
                COPYRIGHT © EDUTECH ABT, {new Date().getFullYear()}
              </Typography>
            </Box>
          </Grid>
          <Grid item md={2} sm={12} xs={12}>
            <Box
              className={classes.footerItems}
              textAlign={isOverMobile ? "left" : "center"}
              marginBottom="2rem"
            >
              {/* <Box display="flex" justifyContent="center" className={classes.footerItems}> */}
              <Typography variant="body2">Discover</Typography>
              <Link to="/" className={classes.link}>
                <Typography variant="caption">Home</Typography>
              </Link>
              {/* <Link to="/about">
                            <Typography variant="caption">About</Typography>             
                            </Link> */}
              <Link to="/faq" className={classes.link}>
                <Typography variant="caption">FAQ</Typography>
              </Link>
              <a
                href="https://edutech.global/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                <Typography variant="caption">Privacy policy</Typography>
              </a>
              <a
                href="https://edutech.global/security-awareness"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                <Typography variant="caption">Security Awareness</Typography>
              </a>
            </Box>
          </Grid>
          <Grid item md={2} sm={12} xs={12}>
            <Box
              className={classes.footerItems}
              textAlign={isOverMobile ? "left" : "center"}
              marginBottom="2rem"
            >
              <Typography variant="body2">Contact Us</Typography>
              {/* <Typography variant="caption">
                                <strong>Address: </strong>
                                Vibranium Valley 42, Local Airport Road, Ikeja, Lagos
                            </Typography> */}
              <Typography variant="caption">
                <strong>Email: </strong>
                <a
                  href="mailto:support@educollectfinance.com"
                  className={classes.link}
                >
                  support@educollectfinance.com
                </a>
              </Typography>
              <Typography variant="caption">
                <strong>Phone: </strong>
                +2348143906442
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default Footer
